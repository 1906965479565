import React from "react"
import {
  Box,
  styled,
  Tab,
  Typography,
  OutlinedInput,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  FormHelperText
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { TabList, TabContext, TabPanel, LoadingButton } from "@mui/lab"
import SendIcon from "@mui/icons-material/Send"
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"

const btnStyles = makeStyles((theme) => ({
  root: {
    borderColor: "#228BE5",
    color: theme.palette.common.white,
    background: "#228BE5",
    textTransform: "none",
    fontFamily: "Courier New",
    width: "100%",
    fontWeight: "540",
    backgroundColor: "#228BE5",
    "&.Mui-disabled": {
      backgroundColor: "#6daee5",
      color: theme.palette.common.white
    },
    "&:active": {
      background: "#2265AB",
      color: "#228BE5"
    },
    height: 50,
    lineHeight: "50px",
    fontSize: 18,
    marginTop: "40px"
  }
}))

const useTextStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 4,
    background: "rgba(255, 253, 253, 0.1)",
    width: "100%",
    maxWidth: "500px",
    color: theme.palette.common.white,
    fontFamily: "Courier New",
    fontSize: 18,
    height: 50,
    lineHeight: "48px",
    "&:focus": {
      color: theme.palette.common.white
    }
  }
}))

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTabs-flexContainer": {
      justifyContent: "center"
    }
  }
}))

const errorStyles = makeStyles((theme) => ({
  root: {
    color: "#d13333",
    fontFamily: "Courier New",
    fontSize: 16,
    fontWeight: "550",
    marginTop: "20px"
  }
}))

const correntStyles = makeStyles((theme) => ({
  root: {
    color: "#1976d2",
    fontFamily: "Courier New",
    fontSize: 16,
    fontWeight: "550",
    marginTop: "20px"
  }
}))

const menuStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Courier New",
    fontSize: 16,
    fontWeight: "550",
    height: "40px"
  }
}))

const MyTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Courier New",
  fontSize: "20px",
  color: "#AAC0D6",
  margin: "20px 0px 2px 0px",
  paddingLeft: "5px",
  fontWeight: "550"
}))

const MyHelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
  fontSize: "14px",
  fontWeight: "560px",
  fontFamily: "Courier New",
  marginLeft: "5px"
}))

const Container = styled(Box)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  [theme.breakpoints.up("xs")]: {
    width: "100%"
  },
  [theme.breakpoints.up("sm")]: {
    width: theme.breakpoints.values.sm
  },
  [theme.breakpoints.up("md")]: {
    width: "540px"
  },
  [theme.breakpoints.up("lg")]: {
    width: "540px"
  },
  margin: "15px auto",
  paddingLeft: "60px",
  marginLeft: "20px",
  marginRight: "10px",
  [theme.breakpoints.down("lg")]: {
    paddingLeft: "40px"
  },
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  [theme.breakpoints.down("md")]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
}))

const Content = styled(Box)(({ theme }) => ({
  borderRadius: 10,
  width: "102%",
  height: "100%",
  backgroundColor: "#14141C",

  [theme.breakpoints.down("md")]: {
    width: "102%"
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%"
  }
}))

const TabListStyle = styled(TabList)(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  alignSelf: "center"
}))

const TabStyle = styled(Tab)(({ theme }) => ({
  fontFamily: "Courier New",
  textTransform: "none",
  color: "#AAC0D6",
  fontSize: 22,
  fontWeight: "bold",
  padding: `${theme.spacing(3)} ${theme.spacing(6)} ${theme.spacing(
    2
  )} ${theme.spacing(6)}`,
  margin: `0 ${theme.spacing(2)}`,
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
    margin: 0,
    padding: `${theme.spacing(1)} 0`
  }
}))

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {},
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: theme.shadows[1],
    fontSize: 13,
    fontFamily: "Courier New",
    fontWeight: "900px"
  }
}))

const ContentIndex = (props) => {
  const menuClasses = menuStyles()
  const classesText = useTextStyles({})
  const btnClasses = btnStyles()
  const helpTextClasses = props.isError ? errorStyles() : correntStyles()
  const classes = useStyles()
  const [value, setValue] = React.useState("Withdraw")
  const [withdrawAmount, setWithdrawAmount] = React.useState(0)
  const [withdrawAddress, setWithdrawAddress] = React.useState("")
  const [donateAmount, setDonateAmount] = React.useState(0)
  const [token, setToken] = React.useState("")
  const [withdrawAddressError, setWithdrawAddressError] = React.useState(false)
  const [withdrawAmountError, setWithdrawAmountError] = React.useState(false)
  const [tokenError, setTokenError] = React.useState(false)
  const [donateAmountError, setDonateAmountError] = React.useState(false)

  const handleTabChange = (event, newValue) => {
    setValue(newValue)
    setTokenError(false)
    setWithdrawAddressError(false)
    setWithdrawAmountError(false)
    setDonateAmountError(false)
    setDonateAmount(0)
    setWithdrawAmount(0)
    setWithdrawAddress("")
  }

  const handleWithdrawAmountChange = (e) => {
    console.log("amount", e.target.value)
    setWithdrawAmount(e.target.value)
    setWithdrawAmountError(false)
  }

  const handleWithdrawAddressChange = (e) => {
    setWithdrawAddress(e.target.value)
    setWithdrawAddressError(false)
  }

  const submitWithdraw = () => {
    if (
      withdrawAddress &&
      withdrawAddress.trim() !== "" &&
      withdrawAmount &&
      withdrawAmount !== 0 &&
      token
    ) {
      props.submitWithdraw(withdrawAddress, withdrawAmount)
      return
    }
    if (withdrawAddress.trim() === "") {
      console.log("withdrawAddress can not be null!")
      setWithdrawAddressError(true)
    }
    if (!withdrawAmount || withdrawAmount === 0) {
      console.log("withdrawAmount can not be null!")
      setWithdrawAmountError(true)
    }
    if (!token || token === "") {
      console.log("token can not be null!")
      setTokenError(true)
    }
  }

  const handleDonateAmountChange = (e) => {
    console.log("handleDonateAmountChange", e.target.value)
    setDonateAmount(e.target.value)
    setDonateAmountError(false)
  }

  const submitDonate = () => {
    if (donateAmount && donateAmount !== 0 && token) {
      props.submitDonate(donateAmount)
    }
    console.log("donateAmount", donateAmount)
    if (!donateAmount || donateAmount === 0) {
      console.log("donateAmount can not be null!")
      setDonateAmountError(true)
    }
    if (!token || token === "") {
      console.log("token can not be null!")
      setTokenError(true)
    }
  }

  const handleTokenChange = (e) => {
    console.log("token change to ", e.target.value)
    setToken(e.target.value)
    setTokenError(false)
    props.onRefreshToken(e.target.value)
  }

  const AddressTab = props.isConnected && (
    <Typography
      style={{ color: "#c0cddb", fontFamily: "Courier New" }}
      sx={{ wordBreak: "break-all" }}
    >
      Current Wallet Address: <br />
      {props.address}
    </Typography>
  )

  const AddressHelperText = (
    <MyHelperText>
      address is invalid, please enter correct wallet address
    </MyHelperText>
  )

  const AmountHelperText = (
    <MyHelperText>please enter the correct amount</MyHelperText>
  )

  const tokenHelperText = <MyHelperText>please select a token</MyHelperText>

  const tokenTitle = (
    <MyTypography
      variant="h5"
      align="left"
      sx={{ display: "flex", justifyContent: "flex-start" }}
    >
      Token{" "}
      <LightTooltip
        placement="right"
        title="Mystiko test tokens are only used for Mystiko Wallet on testnets. They are NOT REAL Tokens."
        arrow
      >
        <HelpOutlineIcon
          sx={{ marginLeft: "7px", color: "#AAC0D6", fontSize: "21px" }}
        ></HelpOutlineIcon>
      </LightTooltip>
    </MyTypography>
  )

  const tokenItems = props.tokens.map((element) => {
    return (
      <MenuItem value={element} classes={menuClasses} key={element}>
        {element}
      </MenuItem>
    )
  })

  const selectItem = (
    <FormControl fullWidth>
      <Select
        onChange={handleTokenChange}
        value={token}
        style={{
          height: 50,
          lineHeight: "24px",
          backgroundColor: "rgba(255, 253, 253, 0.1)",
          fontSize: 18,
          color: "#fff",
          fontFamily: "Courier New"
        }}
      >
        {tokenItems}
      </Select>
      {tokenError && tokenHelperText}
    </FormControl>
  )

  return (
    <Container
      sx={{
        height: "650px"
      }}
    >
      <Content>
        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "#424446",
              margin: "0 auto",
              width: "100%"
            }}
          >
            <TabListStyle onChange={handleTabChange} classes={classes}>
              <TabStyle
                icon={
                  <LightTooltip
                    placement="top-start"
                    title="Get your test tokens for evaluating Mystiko Wallet on testnets."
                    arrow
                  >
                    <HelpOutlineIcon
                      sx={{
                        marginLeft: "7px",
                        color: "#AAC0D6",
                        fontSize: "21px"
                      }}
                    ></HelpOutlineIcon>
                  </LightTooltip>
                }
                iconPosition="end"
                label="Withdraw"
                value="Withdraw"
                sx={{paddingLeft:"62px", paddingRight: "34px"}}
              />
              <TabStyle
                icon={
                  <LightTooltip
                    placement="top-start"
                    title="Give back your unused test tokens after finishing your evaluation of Mystiko Wallet on testnets."
                    arrow
                  >
                    <HelpOutlineIcon
                      sx={{
                        marginLeft: "7px",
                        color: "#AAC0D6",
                        fontSize: "21px"
                      }}
                    ></HelpOutlineIcon>
                  </LightTooltip>
                }
                iconPosition="end"
                label="Donate"
                value="Donate"
                sx={{paddingLeft:"48px", paddingRight: "62px"}}
              />
            </TabListStyle>
          </Box>
          <TabPanel value="Withdraw" style={{ paddingTop: "20px" }}>
            {AddressTab}
            {tokenTitle}
            {selectItem}
            <MyTypography
              variant="h5"
              align="left"
              sx={{ display: "flex", justifyContent: "flex-start" }}
            >
              Address{" "}
              <LightTooltip
                placement="right"
                title="Please type in our public address connected to testnets, e.g. Ethereum Ropsten, BSC Testnet."
                arrow
              >
                <HelpOutlineIcon
                  sx={{ marginLeft: "7px", color: "#AAC0D6", fontSize: "21px" }}
                ></HelpOutlineIcon>
              </LightTooltip>
            </MyTypography>

            <FormControl fullWidth>
              <OutlinedInput
                error={withdrawAddressError}
                classes={classesText}
                placeholder={"Please enter your address"}
                onChange={handleWithdrawAddressChange}
              ></OutlinedInput>
              {withdrawAddressError && AddressHelperText}
            </FormControl>
            <MyTypography
              variant="h5"
              align="left"
              sx={{ display: "flex", justifyContent: "flex-start" }}
            >
              Amount{" "}
              <LightTooltip
                placement="right"
                title={
                  props.withdrawalLimit === undefined ||
                  props.withdrawalLimit === ""
                    ? "The amount of token you want to withdraw."
                    : `You can withdraw at most ${props.withdrawalLimit}.`
                }
                arrow
              >
                <HelpOutlineIcon
                  sx={{ marginLeft: "7px", color: "#AAC0D6", fontSize: "21px" }}
                ></HelpOutlineIcon>
              </LightTooltip>
            </MyTypography>
            <FormControl fullWidth>
              <OutlinedInput
                endAdornment={
                  <InputAdornment position="end">
                    <p style={{ color: "#c9d7e1" }}>{token}</p>
                  </InputAdornment>
                }
                classes={classesText}
                placeholder={"Please enter your amount"}
                onChange={handleWithdrawAmountChange}
                type="number"
              ></OutlinedInput>
              {withdrawAmountError && AmountHelperText}
            </FormControl>
            <LoadingButton
              variant="contained"
              classes={btnClasses}
              loading={props.loading}
              endIcon={<SendIcon />}
              loadingPosition="end"
              onClick={submitWithdraw}
            >
              Withdraw
            </LoadingButton>
            <Typography
              classes={helpTextClasses}
              align="left"
              sx={{ wordBreak: "break-all" }}
            >
              {props.withdrawMessage}
            </Typography>
          </TabPanel>

          <TabPanel value="Donate" style={{ paddingTop: "20px" }}>
            {AddressTab}
            {tokenTitle}
            {selectItem}
            <MyTypography
              variant="h5"
              align="left"
              sx={{ display: "flex", justifyContent: "flex-start" }}
            >
              Amount{" "}
              <LightTooltip
                placement="right"
                title="The amount of token you want to give back."
                arrow
              >
                <HelpOutlineIcon
                  sx={{ marginLeft: "7px", color: "#AAC0D6", fontSize: "21px" }}
                ></HelpOutlineIcon>
              </LightTooltip>
            </MyTypography>
            <FormControl fullWidth>
              <OutlinedInput
                endAdornment={
                  <InputAdornment position="end">
                    <p style={{ color: "#c9d7e1" }}>{token}</p>
                  </InputAdornment>
                }
                classes={classesText}
                placeholder={"Please enter your amount"}
                onChange={handleDonateAmountChange}
                type="number"
              ></OutlinedInput>
              {donateAmountError && AmountHelperText}
            </FormControl>
            <LoadingButton
              variant="contained"
              classes={btnClasses}
              loading={props.loading}
              endIcon={<SendIcon />}
              loadingPosition="end"
              onClick={submitDonate}
            >
              Donate
            </LoadingButton>
            <Typography
              classes={helpTextClasses}
              align="left"
              sx={{ wordBreak: "break-all" }}
            >
              {props.donateMessage}
            </Typography>
          </TabPanel>
        </TabContext>
      </Content>
    </Container>
  )
}

export default ContentIndex
