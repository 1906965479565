const networkConfig = {
  netId3: {
    contractAddress: '0x02A304Cdd05F56fB1e61f23A80e4fc138F219E1F',
    explorerUrlPrefix: 'https://ropsten.etherscan.io/tx',
    networkName: 'Ropsten Testnet',
  },
  netId97: {
    contractAddress: '0x39bb4ae7b91AB77CA1994C2Ce4323E90E68C553A',
    explorerUrl: 'https://testnet.bscscan.com/tx',
    networkName: 'BSC Testnet',
  },
  netId5: {
    contractAddress: '0xf8B22b5EdabB5aeC90301Bddb52660328d8EF325',
    explorerUrl: 'https://goerli.etherscan.io/',
    networkName: 'Goerli Testnet',
  },
  netId80001: {
    contractAddress: '0x83b47ab498a1B8Bbd25A2bbeB6acE1B2ab760C71',
    explorerUrl: 'https://mumbai.polygonscan.com/tx/',
    networkName: 'Mumbai Testnet',
  },
  netId43113: {
    contractAddress: '0xbc0E42cFFd54f0baff44561Bdc6fEa7f56FB3290',
    explorerUrl: 'https://testnet.snowtrace.io/',
    networkName: 'Avalanche FUJI C-Chain',
  },
  netId1287: {
    contractAddress: '0xf8B22b5EdabB5aeC90301Bddb52660328d8EF325',
    explorerUrl: 'https://moonbase.moonscan.io',
    networkName: 'Moonbase Alpha',
  },
  netId4002: {
    contractAddress: '0xf8B22b5EdabB5aeC90301Bddb52660328d8EF325',
    explorerUrl: 'https://explorer.testnet.fantom.network/',
    networkName: 'Fantom Testnet',
  },
}

const abi = [
  {
    inputs: [
      {
        internalType: "contract ERC20Token[]",
        name: "_tokens",
        type: "address[]",
      },
      { internalType: "uint256[]", name: "_periods", type: "uint256[]" },
      { internalType: "uint256[]", name: "_amounts", type: "uint256[]" },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "constructor",
    signature: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "contract ERC20Token",
        name: "token",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "period",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "TokenQuotaSet",
    type: "event",
    signature:
      "0xda01cca05a1edc249ac6ec8a9af5b776b4957a44cb4e0198caba683d76f7b103",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "contract ERC20Token",
        name: "token",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "donor",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "totalSupply",
        type: "uint256",
      },
    ],
    name: "TokensDonated",
    type: "event",
    signature:
      "0x23818a79f4abb49adf42858bf1166f15816750b77dfaa19595f4de03e3e31584",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "contract ERC20Token",
        name: "token",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "totalSupply",
        type: "uint256",
      },
    ],
    name: "TokensWithdrawn",
    type: "event",
    signature:
      "0x6b83b455c317523498e4e86849438d4356ad79ba6b355a5e6d5bc05eca6c780f",
  },
  {
    constant: true,
    inputs: [],
    name: "RATE_LIMIT_TIME",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
    signature: "0x9351638e",
  },
  {
    constant: true,
    inputs: [],
    name: "TIME",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
    signature: "0xbeae6637",
  },
  {
    constant: false,
    inputs: [
      { internalType: "contract ERC20Token", name: "_token", type: "address" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
    ],
    name: "donate",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
    signature: "0xe69d849d",
  },
  {
    constant: true,
    inputs: [
      { internalType: "contract ERC20Token", name: "_token", type: "address" },
    ],
    name: "getBalance",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
    signature: "0xf8b2cb4f",
  },
  {
    constant: true,
    inputs: [],
    name: "getCurrent",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
    signature: "0x35a5cfcb",
  },
  {
    constant: true,
    inputs: [{ internalType: "string", name: "symbol", type: "string" }],
    name: "getTokenBySymbol",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
    signature: "0xefa74f1f",
  },
  {
    constant: true,
    inputs: [],
    name: "getTokenSymbols",
    outputs: [{ internalType: "string[]", name: "", type: "string[]" }],
    payable: false,
    stateMutability: "view",
    type: "function",
    signature: "0x05b93816",
  },
  {
    constant: false,
    inputs: [
      { internalType: "contract ERC20Token", name: "_token", type: "address" },
      { internalType: "uint256", name: "_period", type: "uint256" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
    ],
    name: "modifyQuota",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
    signature: "0xce8a7f27",
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "contract ERC20Token[]",
        name: "_tokens",
        type: "address[]",
      },
      { internalType: "uint256[]", name: "_periods", type: "uint256[]" },
      { internalType: "uint256[]", name: "_amounts", type: "uint256[]" },
    ],
    name: "setQuotas",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
    signature: "0xbe26764b",
  },
  {
    constant: true,
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "tokenQuotas",
    outputs: [
      { internalType: "uint256", name: "period", type: "uint256" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
    signature: "0xa9e1d1de",
  },
  {
    constant: false,
    inputs: [
      { internalType: "contract ERC20Token", name: "_token", type: "address" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
      { internalType: "address", name: "withdrawer", type: "address" },
    ],
    name: "withdraw",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
    signature: "0x69328dec",
  },
  {
    constant: false,
    inputs: [
      { internalType: "contract ERC20Token", name: "_token", type: "address" },
    ],
    name: "withdrawAll",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
    signature: "0xfa09e630",
  },
];

export function getFaucetInstance(web3) {
  let contractAddress = getNetwork(web3).contractAddress
  return web3 === undefined ? null : new web3.eth.Contract(abi, contractAddress)
}

export function getNetworkName(web3) {
  let networkName = getNetwork(web3).networkName
  return networkName;
}

export function checkNetwork(chainId){
  return networkConfig[`netId${chainId}`] !== undefined && networkConfig[`netId${chainId}`] !== {}
}

export function networkList() {
  var array = []
  for (var item in networkConfig) {
    array.push(networkConfig[item].networkName)
  }
  return array;
}

function getNetwork(web3) {
  return networkConfig[`netId${web3.currentProvider.networkVersion}`]
}

export default getFaucetInstance
