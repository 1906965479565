import React from "react"
import { Box, styled, SvgIcon } from "@mui/material"
import logo from "../../public/static/logo.svg"

const Container = styled(Box)(
  ({ theme }) => ({
    width: "100%",
    height: 70,
    lineHeight: 70,
    background: "#14141C"
  }),
  { name: "headerContainer" }
)

const MySvgIcon = styled(SvgIcon)(({ theme }) => ({
  marginLeft: "50px",
  width: 186,
  height: 58,
  cursor: "pointer",
  color: "transparent",
  [theme.breakpoints.down("sm")]: {
    width: 126,
    height: 44,
    margin: "2px auto",
  }
}))

const Content = styled(Box)(
  ({ theme }) => ({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    alignSelf: "center",
    padding: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(2)}`,
    margin: "0 auto"
  }),
  { name: "headerContent" }
)

const Header = () => {
  return (
    <Container>
      <Content>
        <MySvgIcon
          component={logo}
          viewBox="0 0 186 58"
        />
      </Content>
    </Container>
  )
}

export default Header
