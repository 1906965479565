import "./App.css"
import { PureComponent } from "react"
import {
  Box,
  styled,
  SvgIcon,
  Typography,
  Button,
  Hidden,
  Alert,
  IconButton,
  Collapse
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import {
  getFaucetInstance,
  getNetworkName,
  checkNetwork,
  networkList
} from "./contract/abi/ERC20Faucet"
import getTokenInstance from "./contract/abi/ERC20Token"
import Content from "./component/Content"
import Header from "./component/Header"
import backgroundImage from "./public/static/background.svg"
import getUnitByDecimal from "./utils/numberFormat"
const Web3 = require("web3")

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  width: "100%"
}))

const InfoBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  paddingTop: "20px",
  [theme.breakpoints.down("md")]: {
    left: "500px"
  },
  [theme.breakpoints.between("md", "lg")]: {
    left: "580px",
    height: "300px"
  },
  [theme.breakpoints.up("lg")]: {
    left: "720px"
  }
}))

const ConnectBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#222230",
  display: "flex",
  alignItems: "center",
  padding: "4px 2px",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column"
  },
  justifyContent: "center",
  fontSize: "15px"
}))

const MyTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Courier New",
  fontSize: "22px",
  color: "#AAC0D6",
  margin: "35px 0px 10px 0px",
  paddingLeft: "5px",
  fontWeight: "550"
}))

class App extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      currentTokenBalance: "",
      currentTokenAddress: "",
      currentNetworkName: "",
      currentWithdrawLimit: "",
      ethAccount: "",
      withdrawAddress: "",
      withdrawAmount: 0,
      tabValue: 0,
      loading: false,
      withdrawMessage: "",
      donateMessage: "",
      isError: false,
      tokenNames: [],
      web3: {},
      faucet: {},
      isConnected: false,
      alertOpen: false,
      alertMessage: "Unknown exception!",
      alertType: "error"
    }
  }
  async componentDidMount() {}

  handleWithdrawAmountChange(event) {
    const withdrawAmount = event.target.value
    this.setState({ withdrawAmount })
  }

  async submitWithdraw(address, amount) {
    console.log("即将提交", "address:" + address, "amount:" + amount)
    let tokenInstance = getTokenInstance(
      this.state.web3,
      this.state.currentTokenAddress
    )
    let decimals = await tokenInstance.methods.decimals().call()
    const formatAmount = this.state.web3.utils.toWei(
      amount,
      getUnitByDecimal(decimals)
    )
    let withdrawResp
    try {
      this.setState({ loading: true })
      withdrawResp = await this.state.faucet.methods
        .withdraw(this.state.currentTokenAddress, formatAmount, address)
        .send({
          from: this.state.ethAccount
        })
      console.log("withdrawResp", withdrawResp)
      this.setState({
        isError: false,
        withdrawMessage:
          "Withdraw success. Transaction hash: " + withdrawResp.transactionHash
      })
    } catch (err) {
      console.error("err", err)
      this.setState({
        isError: true,
        withdrawMessage:
          "Withdraw failed. Reason: " + err.code + ", " + err.message
      })
    }
    this.setState({ loading: false })
    let balance = await this.state.faucet.methods
      .getBalance(this.state.currentTokenAddress)
      .call()
    balance = this.state.web3.utils.fromWei(balance, getUnitByDecimal(decimals))
    this.setState({
      currentTokenBalance: balance
    })
    this.clearalert()
  }

  async submitDonate(amount) {
    let tokenInstance = getTokenInstance(
      this.state.web3,
      this.state.currentTokenAddress
    )
    if (!tokenInstance) {
      this.alert("error", "Get token instance error!")
      console.err("Get token instance error!")
      return
    }
    let decimal = await tokenInstance.methods.decimals().call()
    const formatAmount = this.state.web3.utils.toWei(
      amount,
      getUnitByDecimal(decimal)
    )
    let donateResp
    try {
      this.setState({ loading: true })
      // approve
      await tokenInstance.methods
        .approve(this.state.faucet.options.address, formatAmount)
        .send({
          from: this.state.ethAccount
        })
      // donate
      donateResp = await this.state.faucet.methods
        .donate(this.state.currentTokenAddress, formatAmount)
        .send({
          from: this.state.ethAccount
        })
      this.setState({
        isError: false,
        donateMessage:
          "Donate success. Transaction hash: " +
          String(donateResp.transactionHash)
      })
    } catch (err) {
      console.error("err", err)
      this.setState({
        isError: true,
        donateMessage: "Donate failed. Reason: " + err.code + ", " + err.message
      })
    }
    this.setState({ loading: false })
    let balance = await this.state.faucet.methods
      .getBalance(this.state.currentTokenAddress)
      .call()
    balance = this.state.web3.utils.fromWei(balance, getUnitByDecimal(decimal))
    this.setState({
      currentTokenBalance: balance
    })
    this.clearAlert()
  }

  async onRefreshToken(currentToken) {
    console.log("token refresh", currentToken)
    console.log("faucet", this.state.faucet)
    let currentTokenAddress = await this.getTokenAddressByName(currentToken)
    let currentTokenBalance = await this.state.faucet.methods
      .getBalance(currentTokenAddress)
      .call()
    let tokenInstance = getTokenInstance(this.state.web3, currentTokenAddress)
    let decimals = await tokenInstance.methods.decimals().call()
    currentTokenBalance = this.state.web3.utils.fromWei(
      currentTokenBalance,
      getUnitByDecimal(decimals)
    )
    let tokenQuota = await this.state.faucet.methods.tokenQuotas(currentTokenAddress).call()
    let currentWithdrawLimit = this.getCurrentTokenWithdrawLimit(
      tokenQuota,
      decimals
    )
    // 获取当前metamask账户地址
    const accounts = await this.state.web3.eth.getAccounts()
    const ethAccount = accounts[0]
    this.setState({
      currentTokenBalance: currentTokenBalance,
      currentTokenAddress: currentTokenAddress,
      currentWithdrawLimit: currentWithdrawLimit,
      ethAccount: ethAccount
    })
    this.clearAlert()
  }

  async getTokenAddressByName(token) {
    let tokenAddress = await this.state.faucet.methods
      .getTokenBySymbol(token)
      .call()
    return tokenAddress
  }

  async initInfoByMetamask(faucet, web3) {
    let tokenNames = await faucet.methods.getTokenSymbols().call()
    this.setState({
      tokenNames: tokenNames
    })
    //获取当前metamask账户地址
    const accounts = await web3.eth.getAccounts()
    const ethAccount = accounts[0]
    const currentNetworkName = getNetworkName(web3)
    this.setState({
      ethAccount: ethAccount,
      withdrawAddress: ethAccount,
      currentNetworkName: currentNetworkName
    })
  }

  alert(type, msg) {
    this.setState({
      alertOpen: true,
      alertMessage: msg,
      alertType: type
    })
  }

  async connectToMetamask() {
    if (
      !typeof window.ethereum === "undefined" ||
      !window.ethereum.isMetaMask
    ) {
      this.alert(
        "error",
        "Metamask is not found, you must install metamask in your browser first!"
      )
      return
    }
    const chainId = window.ethereum.networkVersion
    console.log("chainId", chainId)
    if (!checkNetwork(chainId)) {
      let errMsg =
        `Do not support current network! Only following options: ${this.getSupportedNetworkList()}.`
      this.alert("error", errMsg)
      console.error(
        "Faucet do not support this network currently which your metamask located!"
      )
      return
    }
    let web3
    try {
      let resp = await window.ethereum.request({
        method: "eth_requestAccounts"
      })
      console.log("resp", resp)
      web3 = new Web3(window.ethereum)
    } catch (err) {
      this.alert("error", "Connect your metamask exception!")
      console.error(err)
      return
    }
    let faucet = getFaucetInstance(web3)
    this.setState({
      web3: web3,
      faucet: faucet,
      isConnected: true
    })
    await this.initInfoByMetamask(faucet, web3)
    window.ethereum.on("accountsChanged", (accounts) => {
      console.log("accountsChanged", accounts[0])
      this.setState({
        withdrawAddress: accounts[0],
        ethAccount: accounts[0]
      })
    })
    window.ethereum.on("disconnect", (err) => {
      console.log("disconnect event", err)
    })
    window.ethereum.on("chainChanged", () => this.handleChainChange())
    this.clearAlert()
  }

  getSupportedNetworkList() {
    let str = '';
    let arr = networkList();
    for (let i = 0; i < arr.length; i++) {
      if (i !== arr.length - 1 ) {
        str += arr[i] + ', '
      } else {
        str += arr[i]
      }
    }
    return str;
  }

  async handleChainChange() {
    console.log("handleChainChange")
    window.location.reload()
  }

  clearAlert() {
    this.setState({
      alertOpen: false,
      alertMessage: "Unknown exception!",
      alertType: "error"
    })
  }

  getCurrentTokenWithdrawLimit(tokenQuota, decimals) {
    console.log('tokenQuota' ,tokenQuota)
    let amount = this.state.web3.utils.fromWei(tokenQuota.amount, getUnitByDecimal(decimals)) 
    let period = tokenQuota.period
    let timeLimit = period <= 60 ? `${period} seconds` : period <= 3600 ? `${period/60} minutes` : `${period/3600} hours`
    return `${amount} tokens every ${timeLimit}`
  }

  render() {
    const InfoBoxNetworkItem = this.state.currentNetworkName.trim().length !==
      0 && (
      <>
        <MyTypography variant="h5" align="left">
          Network
        </MyTypography>
        <Typography
          style={{
            color: "#c0cddb",
            fontFamily: "Courier New",
            textAlign: "left",
            paddingLeft: "5px",
            fontSize: "18px"
          }}
        >
          {this.state.currentNetworkName}
        </Typography>
      </>
    )

    const InfoBoxContent = this.state.currentTokenAddress.trim().length !==
      0 && (
      <>
        <MyTypography variant="h5" align="left">
          Faucet Balance
        </MyTypography>
        <Typography
          style={{
            color: "#c0cddb",
            fontFamily: "Courier New",
            textAlign: "left",
            paddingLeft: "5px",
            fontSize: "18px"
          }}
        >
          {this.state.currentTokenBalance}
        </Typography>
        <MyTypography variant="h5" align="left">
          Token Address
        </MyTypography>
        <Typography
          style={{
            color: "#c0cddb",
            fontFamily: "Courier New",
            textAlign: "left",
            paddingLeft: "5px",
            fontSize: "18px"
          }}
        >
          {this.state.currentTokenAddress}
        </Typography>

        <MyTypography variant="h5" align="left">
          Withdrawal Limit
        </MyTypography>
        <Typography
          style={{
            color: "#c0cddb",
            fontFamily: "Courier New",
            textAlign: "left",
            paddingLeft: "5px",
            fontSize: "18px"
          }}
        >
          {this.state.currentWithdrawLimit}
        </Typography>
      </>
    )

    const connectTab = !this.state.isConnected && (
      <ConnectBox>
        <Typography
          sx={{
            color: "#aac0d6db",
            fontFamily: "Courier New",
            marginRight: "10px"
          }}
        >
          Please click to authorized Faucet to connect to your Metamask!
        </Typography>
        <Button
          variant="contained"
          sx={{
            height: "26px",
            width: "84px",
            fontSize: "14px",
            margin: "3px 0px 3px 10px",
            textTransform: "capitalize"
          }}
          onClick={() => this.connectToMetamask()}
        >
          Connect
        </Button>
      </ConnectBox>
    )

    return (
      <div className="App">
        <Header />
        {connectTab}
        <Collapse in={this.state.alertOpen}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={this.clearAlert.bind(this)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            severity={this.state.alertType}
            variant="filled"
            sx={{
              fontFamily: "Courier New",
              padding: "1px 20px",
              backgroundColor: "#bb2929"
            }}
          >
            {this.state.alertMessage}
          </Alert>
        </Collapse>
        <Box style={{ position: "relative", width: "100%" }}>
          <Container>
            <Content
              submitDonate={this.submitDonate.bind(this)}
              submitWithdraw={this.submitWithdraw.bind(this)}
              onRefreshToken={this.onRefreshToken.bind(this)}
              address={this.state.withdrawAddress}
              currentNetworkName={this.state.currentNetworkName}
              tokens={this.state.tokenNames}
              loading={this.state.loading}
              withdrawMessage={this.state.withdrawMessage}
              donateMessage={this.state.donateMessage}
              isError={this.state.isError}
              isConnected={this.state.isConnected}
              withdrawalLimit = {this.state.currentWithdrawLimit}
            />

            <Hidden smDown={true}>
              <Box
                style={{
                  width: "1050px",
                  boxSizing: "border-box",
                  height: "700px"
                }}
              >
                <SvgIcon
                  component={backgroundImage}
                  viewBox="-50 80 950 800"
                  sx={{
                    width: 900,
                    height: 750
                  }}
                />
              </Box>

              <InfoBox>
                {InfoBoxNetworkItem}
                {InfoBoxContent}
              </InfoBox>
            </Hidden>
          </Container>
        </Box>
        <Box className="footer"></Box>
      </div>
    )
  }
}

export default App
