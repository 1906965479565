function getUnitByDecimal(decimal) {
  var unit
  switch (Number(decimal)) {
    case 0:
      unit = "noether"
      break
    case 1:
      unit = "wei"
      break
    case 3:
      unit = "Kwei"
      break
    case 6:
      unit = "Mwei"
      break
    case 9:
      unit = "Gwei"
      break
    case 12:
      unit = "micro"
      break
    case 15:
      unit = "finney"
      break
    case 18:
      unit = "ether"
      break
    case 21:
      unit = "kether"
      break
    case 24:
      unit = "mether"
      break
    case 27:
      unit = "gether"
      break
    case 30:
      unit = "tether"
      break
    default:
      unit = "ether"
  }
  return unit
}

export default getUnitByDecimal
